@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

td {
  border-bottom: 1px solid #e0e0e0 !important;
}

.parent_hover:hover .parent_text {
  color: #2463eb;
  transition: 0.4s ease-in-out;
}

.parent_hover:hover .parent_btn {
  background: #2463eb;
  transition: 0.4s ease-in-out;
  color: #fff;
}

.arrows {
  animation: shake-animations 3s ease infinite;
  transform-origin: 0% 0%;
}

@keyframes shake-animations {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: scale(1.3) translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.re_content {
  display: none;
}
.btn_hover {
  margin-top: 30px;
}

.re_parent:hover .re_content {
  display: block;
  position: absolute;
  left: 0;
  padding: 30px 20px 50px;
  font-size: 13px;
  height: 30vh;
  width: 200%;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  z-index: 44;
  top: 30px;
  border-radius: 10px;
  overflow-y: scroll;
  color: #000;
}
.arrow {
  animation: shake-animation 3s ease infinite;
  transform-origin: 0% 0%;
}

@keyframes shake-animation {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: scale(1.1) translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.arr {
  animation: shake-animate 3s ease infinite;
  transform-origin: 50% 100%;
}

@keyframes shake-animate {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: scale(1.1) translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.icon {
  animation: rotate 2s linear infinite;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Chrome, Safari, and Opera */
::-webkit-scrollbar {
  width: 6px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-thumb {
  background-color: #0f172b;
}

/* Firefox */
/* For Firefox 64+ */
* {
  scrollbar-color: #0f172b rgba(0, 0, 0, 0.05);
  scrollbar-width: thin;
}

/* For Firefox 63 and below */
* {
  scrollbar-color: #0f172b rgba(0, 0, 0, 0.05);
}

/* Microsoft Edge and IE */
* {
  -ms-overflow-style: none; /* Hide the scrollbar in IE and Edge */
}

*::-webkit-scrollbar {
  width: 6px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.05);
}

*::-webkit-scrollbar-thumb {
  background-color: #0f172b;
}

.react-calendar {
  width: 100%;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  line-height: 1.5em;
}
.react-calendar__navigation__prev2-button {
  display: none;
}
.react-calendar__navigation {
  display: flex;
}
.react-calendar__navigation__prev-button {
  font-weight: bold;
}
.react-calendar__navigation__next-button {
  font-weight: bold;
}
.react-calendar__month-view {
  padding-bottom: 20px;
}
.react-calendar__month-view__weekdays {
  padding: 20px;
  font-size: 12px;
  text-transform: uppercase;
  color: #828282;
}
abbr[title] {
  text-decoration: unset !important;
}
.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation__label {
  font-weight: bold;
}
.react-calendar__navigation button {
  color: #333333;
  min-width: 44px;
  font-size: 16px;
  margin-top: 8px;
}
.react-calendar__tile {
  padding: 5px;
  margin-bottom: 5px;
  color: #000;
}

.react-calendar__tile--now {
  background: #f5f5f5;
  border-radius: 6px;
  font-weight: bold;
  color: gray;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  border-radius: 6px;
  font-weight: bold;
  color: #2463eb;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}
.react-calendar__tile--active {
  background: #2463eb;
  border-radius: 6px;
  font-weight: bold;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #2463eb;
  color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}
.react-calendar__tile--rangeStart {
  border-radius: 6px;
  background: #2463eb;
  color: white;
}
.react-calendar__tile--rangeEnd {
  border-radius: 6px;
  background: #2463eb;
  color: white;
}

#txtPassword {
  -webkit-text-security: disc;
}

.react-calendar {
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  line-height: 1.5em;
}
.react-calendar__navigation__prev2-button {
  display: none;
}
.react-calendar__navigation {
  display: flex;
}
.react-calendar__navigation__prev-button {
  font-weight: bold;
}
.react-calendar__navigation__next-button {
  font-weight: bold;
}
.react-calendar__month-view {
  padding-bottom: 20px;
}
.react-calendar__month-view__weekdays {
  padding: 20px;
  font-size: 12px;
  text-transform: uppercase;
  color: #828282;
}
abbr[title] {
  text-decoration: unset !important;
}
.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation__label {
  font-weight: bold;
}
.react-calendar__navigation button {
  color: #333333;
  min-width: 44px;
  font-size: 16px;
  margin-top: 8px;
}
.react-calendar__tile {
  padding: 5px;
  margin-bottom: 5px;
  color: #000;
}

.react-calendar__tile--now {
  background: #f5f5f5;
  border-radius: 6px;
  font-weight: bold;
  color: gray;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  border-radius: 6px;
  font-weight: bold;
  color: #2463eb;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}
.react-calendar__tile--active {
  background: #2463eb;
  border-radius: 6px;
  font-weight: bold;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #2463eb;
  color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}
.react-calendar__tile--rangeStart {
  border-radius: 6px;
  background: #2463eb;
  color: white;
}
.react-calendar__tile--rangeEnd {
  border-radius: 6px;
  background: #2463eb;
  color: white;
}

.toast {
  animation: toast-line 5s ease infinite;
  background: var(--toast-background);
  height: 3px;
  width: 0%;
}

@keyframes toast-line {
  0% {
    width: 0%;
  }

  50% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}
